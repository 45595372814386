import api from './api'
export default {
    getAll(){
        return api.execute(`GET`,`/subuser`)
    },
    CreatSubUser(data){
        return api.execute(`POST`,`/subuser`,data)
    },
    editSubUser(data){
        return api.execute(`PUT`,`/subuser`,data)
    },
    deleteSubUser(data){
        return api.execute(`DELETE`,`/subuser`,data)
    },
}