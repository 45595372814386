<template>
    <div>
        <Breadcrumbs main="" title="Team Members" />
        <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
            <div slot="with-padding">
              <b-row class="mt-3">
                <b-col md="3" class="input-group">
                  <div class="form-group input-group-square">
                    <b-input-group >
                      <b-input-group-prepend >
                        <span class="input-group-text search-bg"><i class="icofont icofont-search-alt-2"></i></span>
                      </b-input-group-prepend>
                      <b-form-input
                        class=""
                        v-model="filter"
                        placeholder="Type to Search"
                      ></b-form-input>
                    </b-input-group>
									</div>
                </b-col>
                <b-col class="mt-2 mt-md-0 text-md-left text-center">
                  <b-button v-if="plan_details.plan_type == 1 || plan_details.plan_name == 'free'"
                    size="lg" variant="primary" class="btn" @click="OpenUpdatePlan()">Add User +</b-button>
                  <b-button v-else size="lg" variant="primary" class="btn" @click="add_sub_user = true" >Add User +</b-button>
                </b-col>

                <b-col class="col-md-6 col-12 mt-2 mt-md-0">
                  <b-row>
                    <b-col class="col-md-12 text-right">
                      Per Page
                      <b-form-select
                      class="col-md-2 col-6"
                      v-model="perPage"
                      :options="pageOptions"
                    ></b-form-select>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <div class="col-md-12" v-if="loading">
                <!-- <h6 class="sub-title mb-0 text-center">Loading...</h6> -->
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
              </div> 
              <div v-else class="table-responsive datatable-vue text-left">
                <b-table
                  show-empty
                  stacked="md"
                  :items="items"
                  :filter="filter"
                  :current-page="currentPage"
                  :fields="tablefields"
                  :per-page="perPage"
                  @filtered="onFiltered"
                >
                <template #cell(username)="data">
                  <b class="">{{ data.item.username }}</b>
                </template>
                <template #cell(email)="data">
                  <b class="">{{ data.item.email }}</b>
                </template>
                <!-- <template #cell(isactive)="data">
                    <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=data.item.isactive @change="statusUpdate(data.item,'isactive')"><span class="switch-state" :class="data.item.isactive?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>                 
                </template> -->
                <template #cell(readOnly)="data">
                    <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=!data.item.readOnly @change="statusUpdate(data.item,'readOnly')"><span class="switch-state" :class="!data.item.readOnly?'bg-success':'bg-danger'"></span>
                        </label>
                    </div>                 
                </template>
                <template #cell(action)="data">
                    <feather class="text-danger" type="trash-2" title="Delete" @click="deleteSubUser(data.item._id)"></feather>
                    <feather class="text-info ml-2" type="edit" title="Edit" @click="editSubUser(data.item)"></feather>         
                </template>
                </b-table>
              </div>

              <b-col md="6" class="mt-3 p-0">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-subuser" size="lg" @hidden="closeModal()" title="Team Member" class="theme-modal" v-model="add_sub_user" no-close-on-backdrop hide-footer>
        <div class="p-3">
            <form action="" @submit.prevent="AddUser()">
        <b-row>
            <b-col>
                <h6 class="text-primary">{{edit_sub_user?'Edit':'Add'}} your team members to keep them notified!</h6>
            </b-col>
        </b-row><hr>
        <b-row class="mb-2">
            <b-col md="3" class="font-weight-bold">
                User Name
            </b-col>
            <b-col md="4">
                <b-form-input size="sm" type="text" name="name" v-model="input.username" :state="validateUsername" placeholder="Username" maxlength="50" required></b-form-input>
                <b-form-invalid-feedback :state="validateUsername">{{ fullname_err_text }}</b-form-invalid-feedback>
            </b-col>
        </b-row><hr>
        <b-row class="mb-2">
            <b-col md="3" class="font-weight-bold">
                Member Email
            </b-col>
            <b-col md="4">
                <b-form-input size="sm" type="email" name="name" v-model="input.email" placeholder="Email" maxlength="100" required></b-form-input>
            </b-col>
        </b-row><hr>
        <b-row class="mb-2">
            <b-col md="3" class="font-weight-bold">
                Password
            </b-col>
            <b-col md="4">
                <b-form-input size="sm" type="text" name="name" maxlength="25" v-model="input.password" placeholder="Password" required></b-form-input>
            </b-col>
        </b-row><hr>
        <!-- <b-row class="mb-2">
            <b-col md="3" class="font-weight-bold">
                Read Only Status
            </b-col>
            <b-col md="4">
                <input class="checkbox_animated" name="case_sensitive" type="checkbox" v-model="input.readOnly" />
            </b-col>
        </b-row><hr> -->
        <b-row class="mt-3 text-center">
            <b-col>
                <b-button type="submit" variant="primary" :disabled="disable_submit">{{edit_sub_user?'Edit User':'Add New User'}}</b-button>
            </b-col>
        </b-row>
    </form>
    </div>
    </b-modal>
 </div>
</template>


<script>
import SubUserController from "../../services/subUserController";
export default {
    name: 'SubUser',
    components:{},
    data(){
        return{
            disable_submit:false,
            input:{username:''},
            add_sub_user:false,
            edit_sub_user: false,
            items:[],
            totalRows:0,
            filter:null,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 50],
            tablefields: [
                          { key: "username", label: "Name", sortable: true },
                          { key: "email", label: "Email", sortable: true },
                          { key: "readOnly", label: "Editor Access", sortable: false },
                          { key: "action", label: "Action", sortable: false },
                        ],
            loading: false,
            fullname_err_text:'',
            plan_details:{}
        }
    },
    mounted(){
      this.plan_details = this.$root.$data.plan_details
      this.getSubUsers()
    },
    computed:{
      validateUsername() {
        if(!this.Usernamevalidate()){
          return false;
        }
        return true;
      },
    },
    methods:{
        async getSubUsers(){
            this.loading = true;
            this.items = []
            let response = await SubUserController.getAll()
            // if (response.result) {
                this.items = response.data;
                this.totalRows = this.items.length;
            // }
            this.loading = false;
        },
        validateEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(!regex.test(email)){
              this.$toasted.show('Invalid email', {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
              return false
            }
            return true;
          },
        async AddUser() {
          if(this.validateEmail(this.input.email) && this.Usernamevalidate()){
            this.disable_submit = true
            var payload = {"username": this.input.username,"subUserEmail": this.input.email,"password": this.input.password,"readOnly": true}
            var response
            if(this.edit_sub_user){
                var p =  {"username": this.input.username,"password": this.input.password,"email": this.input.email}
                var e_payload = { "subUserId": this.input._id,"changePayload": p}
                response = await SubUserController.editSubUser(e_payload);
            }
            else{
                response = await SubUserController.CreatSubUser(payload);
            }
            
            if(response.result){
                this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
                this.add_sub_user = false;
                this.getSubUsers()
            }else{
                this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
            }
            this.disable_submit = false
        }
      },
      editSubUser(data){
          this.add_alers_modal = false
          this.add_sub_user = true
          this.input = data;
          this.edit_sub_user = true;
        },
     async deleteSubUser(id){
        if(confirm("Are you sure want to delete?")){
          let response = await SubUserController.deleteSubUser({"subUserId": id});
          if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
            this.getSubUsers()
          }
          else{
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
            this.getSubUsers()
          }
        }
      },
        closeModal(){
          this.input={}
          this.add_sub_user = false
          this.edit_sub_user = false;
          this.getSubUsers();
        },
        onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async statusUpdate(data,txt){
        var payload
        var s = true
        switch(txt){
            case 'isactive':
                if(data.isactive == true){
                        s = false
                    }
                    if (data.isactive == false) {
                    data.isactive = true;
                    } else {
                    data.isactive = false;
                    }
                    payload = {"subUserId": data._id, "changePayload": {"isactive":s}}
                break;
            case 'readOnly':
                    if(data.readOnly == true){
                        s = false
                    }
                    if (data.readOnly == false) {
                    data.readOnly = true;
                    } else {
                    data.readOnly = false;
                    }
                    payload = {"subUserId": data._id, "changePayload": {"readOnly":s}}
                break;
        }
        let response = await SubUserController.editSubUser(payload)
        if(response.result){
            this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        }
        else{
          this.getSubUsers()
          this.$toasted.show(response.message, {theme: 'outline',position: "bottom-center", type: 'error', duration: 2000});
        }
      },
      openOtpModal(data){
        this.otp_data = []
        this.otp_data = data
      },
       copyUrl(txt){
          const el = document.createElement('textarea');
          el.addEventListener('focusin', e => e.stopPropagation());
          el.value = txt;
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
          this.$toasted.show('URL copied to clipboard.', {theme: 'outline',position: "bottom-center", type: 'success', duration: 2000});
        },
        Usernamevalidate() {
        var fullName = this.input.username
        const regex = /^[a-zA-Z0-9 ]+$/; // Alphanumeric and space characters only
        const maxLength = 50; // Maximum length of the full name field
        if (this.input.username && this.input.username.length >= maxLength) {
          this.fullname_err_text = "Username cannot exceed "+maxLength+" characters"
          return false;
        }
        else if (!regex.test(fullName) && this.input.username != '') {
          this.fullname_err_text = "Username can only contain alphanumeric characters and spaces"
          return false;
        }
        else if(this.input.username == ''){
          this.fullname_err_text = "Username required"
          return false;
        }
        return true;
      },
      OpenUpdatePlan(){
        this.$toasted.show('Please Upgrade your plan.', {theme: 'outline',position: "bottom-center", type: 'error', duration: 3000});
        var el = document.querySelector('#upgrade_btn');
        if(el){
          el.click()
        }    
      }
    }
}

</script>